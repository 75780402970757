import React, { useCallback, useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'

const useContatForm = (FormSchema, language) => {
    emailjs.init('2GnSqixQpo9ScGETL')
    const [isDirty, setIsDirty] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [isFormSent, setIsFormSent] = useState(false)
    const getFormInit = useCallback(() => {
        let formFields = {}
        FormSchema.forEach((field) => {
            formFields[field.name] = {
                ...field,
                value: '',
                isValid: true,
                isRequired: field.isRequired,
            }
        })
        return formFields
    }, [FormSchema])
    const [formFields, setFormFields] = useState(getFormInit())
    useEffect(() => setFormFields(getFormInit()), [language])
    const notify = (message) =>
        toast.error(message, {
            position: 'top-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
        })

    function getEmailMessage(formFields) {
        let message = ''
        Object.keys(formFields).forEach((fieldKey) => {
            let fieldValue = formFields[fieldKey].value
            if (formFields[fieldKey].type === 'select') {
                fieldValue = ''
                formFields[fieldKey].value.forEach((option) => {
                    if (fieldValue !== '') fieldValue += ','
                    fieldValue += option.value
                })
            }
            message += `${formFields[fieldKey].label} - ${fieldValue} \n`
        })
        return message
    }

    const sendEmail = async () => {
        setIsDirty(true)
        if (validateForm()) {
            setIsSending(true)
            try {
                const result = await emailjs.send(
                    'maxshop_y5wec8c',
                    'maxshop_3ypvy8k',
                    {
                        message: getEmailMessage(formFields),
                    }
                )
                if (result.status === 200) {
                    setIsFormSent(true)
                }
            } catch (err) {
                notify('Server error')
            }
            setIsSending(false)
        } else {
            notify('Oops, there are invalid fields 😟!')
            return false
        }
    }
    const handleFieldChange = (fieldName, value) => {
        setFormFields((state) => ({
            ...state,
            [fieldName]: { ...state[fieldName], value: value },
        }))

        if (isDirty) {
            validateForm(fieldName, value)
        }
    }

    const hadnleFieldValidation = (fieldName, isValid) => {
        setFormFields((state) => ({
            ...state,
            [fieldName]: { ...state[fieldName], isValid: isValid },
        }))
    }

    function validateForm(field, value = null) {
        let countet = 0
        const fieldsKeys = field ? [field] : Object.keys(formFields)
        fieldsKeys.forEach((fieldKey) => {
            const newValue = value || formFields[fieldKey].value
            if (formFields[fieldKey].isRequired && newValue.length === 0) {
                hadnleFieldValidation(fieldKey, false)
                countet++
                return
            } else if (newValue.length > 0) {
                if (
                    formFields[fieldKey].type === 'tel' &&
                    !PHONE_REGEX.test(newValue)
                ) {
                    hadnleFieldValidation(fieldKey, false)
                    return
                }
            }
            hadnleFieldValidation(fieldKey, true)
        })

        return countet === 0 ? true : false
    }
    return {
        sendEmail,
        formFields,
        handleFieldChange,
        isFormSent,
        isDirty,
        isSending,
    }
}

export default useContatForm

const PHONE_REGEX = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/
